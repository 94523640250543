import * as Sentry from "@sentry/nextjs";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import Router from "next/router";
import { getPlaiceholder } from "plaiceholder";
import React from "react";
import * as Yup from "yup";
import LoginImage from "../../../../shared/components/auth/LoginImage";
import StyledLink from "../../../../shared/components/global/StyledLink";
import {
  LoginContainer,
  LoginForm,
  LoginGrid,
  LoginPassword,
  Message,
} from "../../../../shared/styles/authentication";
import { Button, ButtonSizes } from "../../../../shared/styles/button";
import { Input, InputTextbox } from "../../../../shared/styles/input";
import { Logo } from "../../../../shared/styles/logo";
import { FormError } from "../../../../shared/styles/validation";
import { isUserLoggedIn } from "../../../../shared/utils/auth";
import Seo from "../../components/global/Seo";
import { SignInWithGoogle } from "../../components/global/authentication/Social/SignInWithGoogle";
import TalentpoolLogo from "/public/svg/Logo.svg";

interface LoginPageProps {
  blurDataURL: any;
  img: any;
}

export default class LoginPage extends React.Component<LoginPageProps, any> {
  state = {
    loading: false,
    error: ``,
    user: null,
    newPasswordRequired: false,
    newPassword: "",
  };

  handleUpdate = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  login = async (email: string, password: string) => {
    this.setState({ loading: true });
    await Auth.signIn(email, password)
      .then(async (user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({
            user: user,
            newPasswordRequired: true,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          if (await isUserLoggedIn("Talente")) {
            Router.push("/app/talent");
          } else {
            Router.push("/app/company/dashboard");
          }
        }
      })
      .catch((err: any) => {
        console.log("error...: ", err);
        this.setState({ error: err.message, loading: false });
        err.message = err.message + `user is ${email}`;
        Sentry.captureException(err);
      });
  };

  completeNewPassword = async () => {
    this.setState({ loading: true });
    return Auth.completeNewPassword(
      this.state.user, // the Cognito User Object
      this.state.newPassword // the new password
    )
      .then((user) => {
        this.setState({ loading: false });
        Router.push("/app/company/dashboard");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message, loading: false });
        Sentry.captureException(err);
      });
  };

  render() {
    const formikProps = {
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Bitte gib eine gültige Email Addresse an.")
          .required("Pflichtfeld."),
        password: Yup.string()
          .min(8, "Länge muss größer als 8 Zeichen sein.")
          .required("Pflichtfeld."),
      }),
      onSubmit: (values) => {
        this.setState({ loading: true });
        this.login(values.email, values.password);
      },
    };

    return (
      <>
        <Seo
          title="Login easyboarding.de"
          description="Login Bereich. Einloggen bei easyboarding."
        />
        <section className="section-login">
          <Formik {...formikProps}>
            {(formik) => (
              <LoginGrid className="login-grid">
                {!this.state.newPasswordRequired ? (
                  <LoginForm>
                    <Logo>
                      <StyledLink href="/">
                        <TalentpoolLogo />
                      </StyledLink>
                    </Logo>
                    <div className="login-header">
                      <h2>Willkommen zurück!</h2>
                    </div>
                    {this.state.error && (
                      <Message className="login-error-message">
                        <b>Login fehlgeschlagen.</b>
                        <p>{this.state.error}</p>
                      </Message>
                    )}
                    {formik != null && (
                      <LoginContainer onSubmit={formik.handleSubmit}>
                        <div
                          style={{
                            maxWidth: 700,
                            width: "100%",
                            flexDirection: "column",
                            display: "flex",
                            rowGap: 15,
                          }}
                        >
                          <div className="login-input-container">
                            <InputTextbox>
                              <Input
                                id="email"
                                className={`${
                                  formik.values.email?.length > 0
                                    ? "form-textbox-entered "
                                    : ""
                                } form-textbox-input`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="email"
                                type="email"
                                value={formik.values.email}
                                error={formik.errors.email != null}
                              />
                              <span className="form-textbox-label">Email</span>
                            </InputTextbox>

                            {formik.touched.email && formik.errors.email ? (
                              <FormError errorText={formik.errors.email} />
                            ) : null}
                          </div>
                          <div className="login-input-container">
                            <LoginPassword>
                              <StyledLink href="/forgot-password">
                                <div className="login-forgot-password">
                                  Passwort vergessen?
                                </div>
                              </StyledLink>
                            </LoginPassword>
                            <InputTextbox>
                              <Input
                                id="password"
                                className={`${
                                  formik.values.password?.length > 0
                                    ? "form-textbox-entered "
                                    : ""
                                } form-textbox-input`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="password"
                                value={formik.values.password}
                                error={
                                  formik.touched.password &&
                                  formik.errors.password != null
                                }
                                type="password"
                              />
                              <span className="form-textbox-label">
                                Passwort
                              </span>
                            </InputTextbox>

                            {formik.touched.password &&
                            formik.errors.password ? (
                              <FormError errorText={formik.errors.password} />
                            ) : null}
                          </div>
                          <Button
                            disabled={this.state.loading}
                            $loading={this.state.loading}
                            primary
                            size={ButtonSizes.l}
                            type="submit"
                          >
                            Anmelden
                          </Button>
                          <hr />
                          <p>Für Talent-Accounts</p>
                          <SignInWithGoogle
                            loading={this.state.loading}
                          ></SignInWithGoogle>
                          <hr />
                          <div className="login-register">
                            <p>
                              Noch nicht dabei? <br /> Jetzt Zugriff via{" "}
                              <a href="mailto:hi@easyboarding.de">
                                hi@easyboarding.de
                              </a>{" "}
                              anfragen.
                            </p>
                          </div>
                        </div>
                      </LoginContainer>
                    )}
                  </LoginForm>
                ) : (
                  <LoginForm>
                    <div
                      style={{
                        maxWidth: 700,
                        width: "100%",
                        flexDirection: "column",
                        display: "flex",
                        rowGap: 15,
                      }}
                    >
                      <div className="login-header">
                        <h2>Neues Passwort erstellen</h2>
                        <p>Bitte lege nun ein Passwort für den Account fest.</p>
                      </div>
                      {this.state.error && (
                        <Message className="login-error-message">
                          <b>Passwortänderung fehlgeschlagen.</b>
                          <p>{this.state.error}</p>
                        </Message>
                      )}
                      <div className="login-input-container">
                        <LoginPassword>
                          <b>Neues Passwort</b>
                        </LoginPassword>
                        <Input
                          onChange={this.handleUpdate}
                          placeholder="Passwort"
                          name="newPassword"
                          value={this.state.newPassword}
                          type="password"
                        />
                      </div>
                      <Button
                        primary
                        type="button"
                        disabled={this.state.loading}
                        $loading={this.state.loading}
                        size={ButtonSizes.l}
                        onClick={this.completeNewPassword}
                      >
                        Passwort festlegen
                      </Button>
                    </div>
                  </LoginForm>
                )}

                <LoginImage
                  img={this.props.img}
                  blurDataURL={this.props.blurDataURL}
                />
              </LoginGrid>
            )}
          </Formik>
        </section>
      </>
    );
  }
}

export const getStaticProps = async () => {
  const { base64, img } = await getPlaiceholder("/images/login-cover.jpg", {
    size: 10,
  });

  return {
    props: {
      img: img.src,
      blurDataURL: base64,
    },
  };
};
