import Image from 'next/image';
import styled from 'styled-components';
import { device } from '../../styles/device';

const LoginImageWrapper = styled.div`
    .login-image {
      height: 100%;
      width: 100%;
      position: relative;
    }
      
    visibility: hidden;
    display: none;

    @media ${device.tablet} {
      position: relative;
      display: block;
      visibility: visible;
    }

    img {
      object-fit: cover;
    }
`;

export default function LoginImage({ img, blurDataURL }) {

  return (
    <LoginImageWrapper>
      <Image
        src={img}
        className="login-image"
        alt="Login Cover Image"
        layout="fill"
        quality={100}
        objectFit='cover'
        blurDataURL={blurDataURL}
        placeholder="blur"
        priority={true}
      />
    </LoginImageWrapper>
  )
}
